export enum AppVisibilityLevel {
  EVERYONE,
  SYSTEM_CONSULTANT_ONLY,
  INJECT_ONLY,
}

export const apps = {
  isa: {
    host: 'http://localhost:4200',
    route: 'isa',
    name: 'APP_ISA_NAME',
    description: 'APP_ISA_DESCRIPTION',
    order: -1,
    embeddedActionIcon: '',
    embeddedActionParam: '',
    appVisibility: AppVisibilityLevel.INJECT_ONLY,
  },
  glasses: {
    host: 'http://localhost:4202',
    route: 'glasses',
    name: 'APP_GLASSES_NAME',
    description: 'APP_GLASSES_DESCRIPTION',
    order: 3,
    embeddedActionIcon: 'uw-glass-add',
    embeddedActionParam: 'action=addSession',
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  glassesV2: {
    host: 'http://localhost:4225',
    route: 'glasses-v2',
    name: 'APP_GLASSES_V2_NAME',
    description: 'APP_GLASSES_DESCRIPTION',
    order: 4,
    embeddedActionIcon: 'uw-glass-add',
    embeddedActionParam: 'action=addSession',
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  visionProfile: {
    host: 'http://localhost:4203',
    route: 'vision-profile',
    name: 'APP_VISION_PROFILE_NAME',
    description: 'APP_VISION_PROFILE_DESCRIPTION',
    order: 0,
    embeddedActionIcon: 'uw-contract-add',
    embeddedActionParam: 'action=addSession',
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  caseHistory: {
    host: 'http://localhost:4204',
    route: 'case-history',
    name: 'APP_CASE_HISTORY_NAME',
    description: 'APP_CASE_HISTORY_DESCRIPTION',
    order: 1,
    embeddedActionIcon: 'uw-contract-add',
    embeddedActionParam: 'action=addSession',
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  calendar: {
    host: 'http://localhost:4206',
    route: 'calendar',
    name: 'APP_CALENDAR_NAME',
    description: 'APP_CALENDAR_DESCRIPTION',
    order: 8,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  measures: {
    host: 'http://localhost:4205',
    route: 'measures',
    name: 'APP_MEASURES_NAME',
    description: 'APP_MEASURES_DESCRIPTION',
    actionButtonComponent: 'CreateActionButtonComponent',
    order: 2,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  workspace: {
    host: 'http://localhost:4208',
    route: 'workspace',
    name: 'APP_WORKSPACE_NAME',
    description: 'APP_WORKSPACE_DESCRIPTION',
    order: 9,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  messages: {
    host: 'http://localhost:4207',
    route: 'messages',
    name: 'APP_MESSAGES_NAME',
    description: 'APP_MESSAGES_DESCRIPTION',
    order: 29,
    embeddedActionIcon: 'uw-message-add',
    embeddedActionParam: 'action=keepSession',
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  marketing: {
    host: 'http://localhost:4217',
    route: 'marketing',
    name: 'APP_MARKETING_NAME',
    description: 'APP_MARKETING_DESCRIPTION',
    order: 30,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  payments: {
    host: 'http://localhost:4212',
    route: 'payments',
    name: 'APP_PAYMENTS_NAME',
    description: 'APP_PAYMENTS_DESCRIPTION',
    actionButtonComponent: 'CreateActionButtonComponent',
    order: 27,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  analytics: {
    host: 'http://localhost:4215',
    route: 'analytics',
    name: 'APP_ANALYTICS_NAME',
    description: 'APP_ANALYTICS_DESCRIPTION',
    order: 35,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  products: {
    host: 'http://localhost:4209',
    route: 'products',
    name: 'APP_PRODUCTS_NAME',
    description: 'APP_PRODUCTS_DESCRIPTION',
    actionButtonComponent: 'CreateActionButtonComponent',
    order: 25,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  times: {
    host: 'http://localhost:4211',
    route: 'times',
    name: 'APP_TIMES_NAME',
    description: 'APP_TIMES_DESCRIPTION',
    order: 12,
    appVisibility: AppVisibilityLevel.EVERYONE,
  },
  migrate: {
    host: 'http://localhost:4214',
    route: 'migrate',
    name: 'APP_MIGRATE_NAME',
    description: 'APP_MIGRATE_DESCRIPTION',
    order: 30,
    appVisibility: AppVisibilityLevel.SYSTEM_CONSULTANT_ONLY,
  },
  integrations: {
    host: 'http://localhost:4299',
    route: 'integrations',
    name: 'APP_INTEGRATIONS_NAME',
    description: 'APP_INTEGRATIONS_DESCRIPTION',
    order: 99,
    appVisibility: AppVisibilityLevel.INJECT_ONLY,
  },
  catalogs: {
    host: 'http://localhost:4220',
    route: 'catalogs',
    name: 'APP_CATALOGS_NAME',
    description: 'APP_CATALOGS_DESCRIPTION',
    order: 37,
    appVisibility: AppVisibilityLevel.INJECT_ONLY,
  },
  businessActivity: {
    host: 'http://localhost:4222',
    route: 'timeline',
    name: 'APP_BUSINESS_ACTIVITY_NAME',
    description: 'APP_BUSINESS_ACTIVITY_DESCRIPTION',
    order: 38,
    appVisibility: AppVisibilityLevel.INJECT_ONLY,
    embeddedActionIcon: '',
    embeddedActionParam: '',
  },
};
